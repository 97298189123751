import { IObjectKeys } from "@/interfaces/IObjectKeys";
import { sortItem } from "@/enums/main/sortOrders";
import { sortController } from "@/utills/sort";
import { getNestedValue } from "@/utills/getNestedValue";

export function useSortedModel(model: IObjectKeys[], activeSort?: sortItem) {
  if (!activeSort) {
    return model;
  }

  const { order, keyNameInModel = "" } = activeSort;
  const sort = sortController(order);

  const data = model ? [...model] : [];
  return data.sort((a, b) => {
    let aValue = getNestedValue(keyNameInModel, a) || "";
    let bValue = getNestedValue(keyNameInModel, b) || "";

    const aType = typeof aValue;
    const bType = typeof bValue;

    if (aType !== "string" && aType !== "number" && aType !== "boolean") {
      aValue = "-1";
    }
    if (bType !== "string" && bType !== "number" && bType !== "boolean") {
      bValue = "-1";
    }

    return sort(aValue, bValue);
  });
}
