import { IObjectKeys } from "@/interfaces/IObjectKeys";

export function getNestedValue(path: string, obj: IObjectKeys): any {
  const nestedFields = path.split("."); // ?;
  let val: any = obj;
  let i = 0;

  while (i < nestedFields.length) {
    const key: string = nestedFields[i];

    if (typeof val !== "object") {
      i = nestedFields.length;
      break;
    }

    if (val[key] === undefined) {
      return null;
    }

    if (typeof val === "object" && val[key]) {
      val = val[key];
      i++;
    } else {
      i = nestedFields.length;
    }
  }

  return val;
}
//
// getNestedValue("updatedAt.fullName", {
//   id: 170,
//   name: "Algeria",
//   isoCode: "AG",
//   icon:
//     "https://telesim-api.dev2.4k.com.ua/share/locations/icon/c5db84d3-da92-4842-b7cf-1112cdb83d64.png",
//   smallImage: "",
//   bigImage:
//     "https://telesim-api.dev2.4k.com.ua/share/locations/bigImage/10942e9b-eb93-46f7-9837-9b481d0cd9c6.png",
//   secondaryBigImage: null,
//   popular: false,
//   active: true,
//   createdAt: "2021-03-30T07:20:31.722Z",
//   updatedAt: "2021-07-29T12:06:20.424Z",
//   countryPlans: [
//     {
//       id: 350,
//       active: true,
//       zone: {
//         id: 12,
//         title: "Zone L",
//         key: "zonel"
//       },
//       planOperators: [
//         {
//           id: 218,
//           active: true,
//           operator: {
//             id: 92,
//             name: "Mobilis",
//             image:
//               "https://telesim-api.dev2.4k.com.ua/share/locations/icon/1696a7f9-856e-4d8a-a110-7d4056c9588d.png",
//             countryId: 170,
//             netGens: [
//               {
//                 id: 287,
//                 active: true,
//                 networkGeneration: {
//                   id: 5,
//                   title: "2G",
//                   key: "2g",
//                   active: true
//                 }
//               },
//               {
//                 id: 284,
//                 active: true,
//                 networkGeneration: {
//                   id: 2,
//                   title: "3G",
//                   key: "three_g",
//                   active: true
//                 }
//               }
//             ]
//           }
//         }
//       ],
//       plan: {
//         id: 1,
//         name: "Tourist",
//         planType: "tourist",
//         description: "Stay connected everywhere",
//         active: true,
//         icon1:
//           "https://telesim-api.dev2.4k.com.ua/share/plans/icon1/7ff7987d-9696-4c2d-9117-f758ffb3bde1.png",
//         icon2: "",
//         packages: [
//           {
//             id: 967,
//             title: "3 GB",
//             product: 32098,
//             defaultPrice: 51,
//             discount: false,
//             discountPercent: null,
//             discountAmount: 0,
//             priceWithDiscount: 51,
//             validity: {
//               id: 4,
//               title: "30 days",
//               period: 30,
//               active: true
//             },
//             purchaseType: {
//               id: 1,
//               title: "Prepaid",
//               key: "prepaid",
//               active: true
//             },
//             plan: {
//               id: 1,
//               name: "Tourist",
//               planType: "tourist",
//               description: "Stay connected everywhere",
//               active: true,
//               icon1:
//                 "https://telesim-api.dev2.4k.com.ua/share/plans/icon1/7ff7987d-9696-4c2d-9117-f758ffb3bde1.png",
//               icon2: ""
//             },
//             dataType: {
//               id: 2,
//               title: "DATA only",
//               key: "data_only",
//               active: true
//             }
//           },
//           {
//             id: 968,
//             title: "1 GB",
//             product: 32097,
//             defaultPrice: 23,
//             discount: false,
//             discountPercent: null,
//             discountAmount: 0,
//             priceWithDiscount: 23,
//             validity: {
//               id: 14,
//               title: "1 year",
//               period: 365,
//               active: true
//             },
//             purchaseType: {
//               id: 1,
//               title: "Prepaid",
//               key: "prepaid",
//               active: true
//             },
//             plan: {
//               id: 1,
//               name: "Tourist",
//               planType: "tourist",
//               description: "Stay connected everywhere",
//               active: true,
//               icon1:
//                 "https://telesim-api.dev2.4k.com.ua/share/plans/icon1/7ff7987d-9696-4c2d-9117-f758ffb3bde1.png",
//               icon2: ""
//             },
//             dataType: {
//               id: 2,
//               title: "DATA only",
//               key: "data_only",
//               active: true
//             }
//           },
//           {
//             id: 969,
//             title: "3 GB",
//             product: 32098,
//             defaultPrice: 56.5,
//             discount: false,
//             discountPercent: null,
//             discountAmount: 0,
//             priceWithDiscount: 56.5,
//             validity: {
//               id: 14,
//               title: "1 year",
//               period: 365,
//               active: true
//             },
//             purchaseType: {
//               id: 1,
//               title: "Prepaid",
//               key: "prepaid",
//               active: true
//             },
//             plan: {
//               id: 1,
//               name: "Tourist",
//               planType: "tourist",
//               description: "Stay connected everywhere",
//               active: true,
//               icon1:
//                 "https://telesim-api.dev2.4k.com.ua/share/plans/icon1/7ff7987d-9696-4c2d-9117-f758ffb3bde1.png",
//               icon2: ""
//             },
//             dataType: {
//               id: 2,
//               title: "DATA only",
//               key: "data_only",
//               active: true
//             }
//           },
//           {
//             id: 966,
//             title: "1 GB",
//             product: 32097,
//             defaultPrice: 19,
//             discount: false,
//             discountPercent: null,
//             discountAmount: 0,
//             priceWithDiscount: 19,
//             validity: {
//               id: 4,
//               title: "30 days",
//               period: 30,
//               active: true
//             },
//             purchaseType: {
//               id: 1,
//               title: "Prepaid",
//               key: "prepaid",
//               active: true
//             },
//             plan: {
//               id: 1,
//               name: "Tourist",
//               planType: "tourist",
//               description: "Stay connected everywhere",
//               active: true,
//               icon1:
//                 "https://telesim-api.dev2.4k.com.ua/share/plans/icon1/7ff7987d-9696-4c2d-9117-f758ffb3bde1.png",
//               icon2: ""
//             },
//             dataType: {
//               id: 2,
//               title: "DATA only",
//               key: "data_only",
//               active: true
//             }
//           }
//         ]
//       }
//     },
//     {
//       id: 352,
//       active: true,
//       zone: null,
//       planOperators: [
//         {
//           id: 220,
//           active: true,
//           operator: {
//             id: 92,
//             name: "Mobilis",
//             image:
//               "https://telesim-api.dev2.4k.com.ua/share/locations/icon/1696a7f9-856e-4d8a-a110-7d4056c9588d.png",
//             countryId: 170,
//             netGens: [
//               {
//                 id: 287,
//                 active: true,
//                 networkGeneration: {
//                   id: 5,
//                   title: "2G",
//                   key: "2g",
//                   active: true
//                 }
//               },
//               {
//                 id: 284,
//                 active: true,
//                 networkGeneration: {
//                   id: 2,
//                   title: "3G",
//                   key: "three_g",
//                   active: true
//                 }
//               }
//             ]
//           }
//         }
//       ],
//       plan: {
//         id: 3,
//         name: "Tourist+",
//         planType: "tourist_plus",
//         description: "Get strong network",
//         active: true,
//         icon1:
//           "https://telesim-api.dev2.4k.com.ua/share/plans/icon1/996000e4-5cfc-4248-8860-a1c8954144b3.png",
//         icon2: "",
//         packages: []
//       }
//     }
//   ],
//   updatedBy: {}
// });
