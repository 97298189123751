import { ComputedRef, computed } from "vue";
import {
  ITableBodyCell,
  ITableBodyRow
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem } from "@/enums/main/sortOrders";
import { sortController } from "@/utills/sort";

function searchSortTargetCell(
  row: ITableBodyRow,
  sorting: ComputedRef<sortItem | null>
): ITableBodyCell | null {
  if (!sorting) {
    return null;
  }

  return (
    row.cells.find(
      ({ sort }: ITableBodyCell) => sort?.keyName === sorting?.value?.keyName
    ) || null
  );
}

export function useSortedTableRows(
  rows: ITableBodyRow[],
  activeSortOption: ComputedRef<sortItem | null>
): ComputedRef<ITableBodyRow[]> {
  return computed(() => {
    if (!activeSortOption.value) {
      return rows;
    }

    return [...rows].sort((a: ITableBodyRow, b: ITableBodyRow) => {
      const aValue: ITableBodyCell | null = searchSortTargetCell(
        a,
        activeSortOption
      );

      const bValue: ITableBodyCell | null = searchSortTargetCell(
        b,
        activeSortOption
      );

      if (!activeSortOption.value) return 0;

      if (aValue?.sort && bValue?.sort) {
        return sortController(activeSortOption.value.order)(
          aValue.sort.value,
          bValue.sort.value
        );
      }

      return 0;
    });
  });
}
