import { sortOrder, sortOrders } from "@/enums/main/sortOrders";

type sortValue = string | number | boolean;

function updateSortValueString(value: sortValue) {
  if (typeof value === "string") {
    return value.toLowerCase();
  }

  return value;
}

export function sortByAsc(a: sortValue, b: sortValue): number {
  const updatedA = updateSortValueString(a);
  const updatedB = updateSortValueString(b);

  if (a === "" || a === null) return 1;
  if (b === "" || b === null) return -1;

  return updatedA > updatedB ? 1 : updatedA < updatedB ? -1 : 0;
}

export function sortByDesc(a: sortValue, b: sortValue): number {
  const updatedA = updateSortValueString(a);
  const updatedB = updateSortValueString(b);

  if (a === "" || a === null) return 1;
  if (b === "" || b === null) return -1;

  return updatedA < updatedB ? 1 : updatedA > updatedB ? -1 : 0;
}

export const sortController = (order: sortOrder) => {
  if (order === sortOrders.asc) {
    return sortByAsc;
  }

  return sortByDesc;
};
