import { reactive } from "vue";
import { ITableHeadTab } from "@/components/Table/ts/interfaces/TableStructure";

export type changeTabType = (
  newConfiguration: ITableHeadTab
) => ITableHeadTab | null;

export type useTableTabData = {
  configuration: ITableHeadTab;
  changeTab: changeTabType;
};

export function useTableTabConfiguration(
  initialViewConfiguration: ITableHeadTab
): useTableTabData {
  const configuration: ITableHeadTab = reactive(initialViewConfiguration);

  return {
    configuration,
    changeTab(newConfiguration: ITableHeadTab) {
      if (newConfiguration) {
        Object.entries(newConfiguration).forEach(([key, value]) => {
          configuration[key] = value;
        });

        return configuration;
      }
      return null;
    }
  };
}
