import { computed, ComputedRef, reactive, ref, Ref, watch } from "vue";
import {
  ITableBodyRow,
  ITableHeadTab
} from "@/components/Table/ts/interfaces/TableStructure";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import {
  changeViewConfigurationType,
  useTableViewConfiguration
} from "@/hooks/tables/modules/baseTable/useTableViewConfiguration";
import { useSortedModel } from "@/hooks/tables/modules/baseTable/useSortedModel";
import { useSortedTableRows } from "@/hooks/tables/modules/baseTable/useSortedTableRows";
import {
  changeTabType,
  useTableTabConfiguration
} from "@/hooks/tables/modules/baseTable/useTableTabConfiguration";

export interface sorting {
  [key: string]: sortItem;
}

interface useBaseTableOptions {
  initialViewConfiguration: ITableViewConfiguration;
  initialSortOptions: sorting;
  model: ComputedRef<any[]> | Ref<any[]>;
  sortRows?: boolean;

  // handlers
  generateRows: (model: any[]) => ITableBodyRow[];
  onViewConfigurationChanged?: (
    newConfiguration: ITableViewConfiguration
  ) => void;
}

interface useTableWithTabsOptions {
  initialTab: ITableHeadTab;
  onTabChanged: (newTab: ITableHeadTab) => void;
}

export interface useTableWithOutViewConfigurationOptions {
  initialSortOptions: sorting;
  model: ComputedRef<any[]>;
  initialTab?: ITableHeadTab;

  // handlers
  generateRows: (model: any[]) => ITableBodyRow[];
  onTabChanged?: (newTab: ITableHeadTab) => void;
}

export interface useTableWithOutViewConfig {
  rows: ComputedRef<ITableBodyRow[]>;
  viewConfiguration?: ITableViewConfiguration;
  changeSorting: (sortByKeyName: string) => void;
  currentSort: sorting;
  sortedModel: ComputedRef<any[]> | Ref<any[]>;
}

export function useTableWithOutViewConfiguration(
  options: useTableWithOutViewConfigurationOptions
): useTableWithOutViewConfig {
  const { generateRows, initialSortOptions, model } = options;

  const currentSort = reactive(initialSortOptions);

  const activeSortOption = computed(() => {
    return (
      Object.values(<sorting>currentSort).find(
        ({ order }) => order !== sortOrders.turnOff
      ) || initialSortOptions[0]
    );
  });

  const sortedModel = ref(useSortedModel(model.value, activeSortOption.value));

  watch(
    [() => model.value, () => activeSortOption.value],
    () => {
      sortedModel.value = useSortedModel(model.value, activeSortOption.value);
    },
    { immediate: true, deep: true }
  );
  return {
    rows: computed(() => {
      return useSortedTableRows(
        generateRows(sortedModel.value || model.value),
        activeSortOption
      ).value;
    }),
    currentSort,
    changeSorting(sortByKeyName: string): void {
      if (currentSort) {
        Object.values(<sorting>currentSort).forEach((option: sortItem) => {
          if (option.keyName === sortByKeyName) {
            const currentSortOrder = option.order;

            option.order =
              currentSortOrder === sortOrders.asc
                ? sortOrders.desc
                : sortOrders.asc;
          } else {
            option.order = sortOrders.turnOff;
          }
        });
      }
    },
    sortedModel
  };
}

export interface useBaseTable {
  rows: ComputedRef<ITableBodyRow[]>;
  changeViewConfiguration: changeViewConfigurationType;
  changeSorting: (sortByKeyName: string) => void;
  viewConfiguration: ITableViewConfiguration;
  currentSort: sorting;
  sortedModel: ComputedRef<any[]> | Ref<any[]>;
}

export function useBaseTable(options: useBaseTableOptions): useBaseTable {
  const {
    generateRows,
    initialSortOptions,
    initialViewConfiguration,
    onViewConfigurationChanged,
    model
  } = options;

  const {
    configuration: viewConfiguration,
    changeViewConfiguration
  } = useTableViewConfiguration(initialViewConfiguration);

  watch(viewConfiguration, (newConfiguration: ITableViewConfiguration) => {
    onViewConfigurationChanged?.(newConfiguration);
  });

  const currentSort = reactive(initialSortOptions || {});

  const activeSortOption = computed(() => {
    return (
      Object.values(<sorting>currentSort).find(
        ({ order }) => order !== sortOrders.turnOff
      ) || initialSortOptions[0]
    );
  });

  const sortedModel = ref(useSortedModel(model.value, activeSortOption.value));

  watch(
    [() => model.value, () => activeSortOption.value],
    () => {
      sortedModel.value = useSortedModel(model.value, activeSortOption.value);
    },
    { immediate: true, deep: true }
  );

  return {
    rows: computed(() => {
      return useSortedTableRows(
        generateRows(sortedModel.value || model.value),
        activeSortOption
      ).value;
    }),
    sortedModel,
    viewConfiguration,
    changeViewConfiguration,
    currentSort,
    changeSorting(sortByKeyName: string): void {
      if (currentSort) {
        Object.values(<sorting>currentSort).forEach((option: sortItem) => {
          if (option.keyName === sortByKeyName) {
            const currentSortOrder = option.order;

            option.order =
              currentSortOrder === sortOrders.asc
                ? sortOrders.desc
                : sortOrders.asc;
          } else {
            option.order = sortOrders.turnOff;
          }
        });
      }
    }
  };
}

export interface useTableWithTabs {
  changeTab: changeTabType;
  selectedTab: ITableHeadTab;
}

export function useTableWithTabs(
  options: useTableWithTabsOptions
): useTableWithTabs {
  const { onTabChanged, initialTab } = options;

  const { configuration: selectedTab, changeTab } = useTableTabConfiguration(
    initialTab
  );

  watch(selectedTab, (newConfiguration: ITableHeadTab) => {
    onTabChanged?.(newConfiguration);
  });

  return {
    changeTab,
    selectedTab
  };
}
