import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "pseudo-table-content" }
const _hoisted_2 = { class: "pseudo-table--head" }
const _hoisted_3 = { class: "pseudo-table--body" }
const _hoisted_4 = {
  key: 0,
  class: "pseudo-table--footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableHeadCell = _resolveComponent("TableHeadCell")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_TableFooter = _resolveComponent("TableFooter")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "pseudo-table inline-table" }, _ctx.$attrs), [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TableRow, { "space-between": _ctx.spaceBetween }, {
          content: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHead, ({ id, size, label, sort, rightAlign }) => {
              return (_openBlock(), _createBlock(_component_TableHeadCell, {
                id: id,
                key: id,
                name: label,
                sort: sort,
                size: size,
                class: _normalizeClass({ 'right-align': rightAlign }),
                onSort: _ctx.sortHandler
              }, null, 8, ["id", "name", "sort", "size", "class", "onSort"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["space-between"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "table-body")
      ]),
      (_ctx.withFooter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_TableFooter, {
              "count-text": _ctx.paginationCapture,
              pagination: {
            total: _ctx.paginationTotal,
            current: _ctx.currentPage
          },
              "per-page": _ctx.viewConfiguration.perPage,
              "total-rows": _ctx.totalRows,
              "use-select": true,
              onChangePage: _ctx.changeCurrentPageHandler,
              onChangePerPage: _ctx.changePerPageHandler
            }, null, 8, ["count-text", "pagination", "per-page", "total-rows", "onChangePage", "onChangePerPage"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 16))
}